import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "./Featured-venue.css";
import { Heart, MapPin, Calendar, Eye, LogIn } from "react-feather";
import { useNavigate } from "react-router-dom";

const MyCarousel = () => {
  const [carouselData, setCarouselData] = useState([]);
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("user"));

  const handleNavigate = (venue) => {
    if (userData) {
      const data = venue;
      navigate("/bookingordershome", { state: { data } });
    } else {
      navigate("/signin");
    }
  };

  const fetchCarouselData = async () => {
    try {
      const response = await fetch(
        `https://api.dremerz.net/api/lawnlinkregisteredgyms?isFeatured=true`
      ); // Replace with your actual API URL
      const data = await response.json();
      console.log(data, "ddd");

      setCarouselData(data);
    } catch (error) {
      console.error("Error fetching carousel data:", error);
    }
  };

  // Fetch data from the API when the component mounts
  useEffect(() => {
    fetchCarouselData();
  }, []);

  return (
    <>
      <div className="container my-4">
        <div className="section-heading text-center aos" data-aos="fade-up">
          <h2>
            Featured <span>Gyms</span>
          </h2>
          <p className="sub-title">
            Advanced sports venues offer the latest facilities, dynamic and
            unique environments for enhanced badminton performance.
          </p>
        </div>

        <div className="custom-carousel">
          <Carousel>
            {carouselData.map((item) => (
              <Carousel.Item key={item.id}>
                <div className="row no-gutters">
                  {/* Image on the left */}
                  <div className="col-12 col-md-6">
                    <img
                      className="d-block w-100 carousel-img"
                      src={`https://api.dremerz.net/uploads/${item.image}`}
                      alt={item.title}
                      style={{
                        height: window.innerWidth <= 768 ? "300px" : "500px",
                        borderRadius: window.innerWidth <= 768 ? "20px" : "",
                      }}
                    />
                  </div>

                  {/* Content on the right, hidden on smaller screens */}
                  <div
                    className="col-md-6 d-flex flex-column justify-content-center p-4 text-white"
                    style={{
                      backgroundColor: "#228988",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3>{item.gymname}</h3>
                    <p>{item.description}</p>
                    <p>
                      <strong>Price:</strong> {item.pricepermonth}
                    </p>
                    <p>
                      <strong>Rating:</strong> {item.rating} With ({item.review}{" "}
                      reviews)
                    </p>
                    <p>
                      <strong>Address:</strong> {item.location}
                    </p>
                    <div style={{ width: "200px" }}>
                      <a
                        href=""
                        onClick={() => {
                          handleNavigate(item);
                        }}
                        className="btn btn-secondary w-100"
                        style={{
                          height: "50px",
                          display: "inline-block",
                        }}
                      >
                        <Calendar className="me-2" /> Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default MyCarousel;
