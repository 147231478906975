import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap modal
import "./Ourservicesadmin.css";
import React from "react";

const OurFeatures = () => {
  const [formData, setFormData] = useState({
    title: "",
    detail: "",
  });
  const [venueData, setVenueData] = useState([]); // Store fetched features data
  const [editData, setEditData] = useState(null); // For editing specific data
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [isEditing, setIsEditing] = useState(false); // Toggle between add and edit modes

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission (for both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      if (isEditing) {
        // Update existing feature
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkOurFeatures/${editData.id}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Data Updated Successfully");
      } else {
        // Add new feature
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkOurFeatures",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Data Saved Successfully");
      }

      setFormData({
        title: "",
        detail: "",
      });
      setShowModal(false); // Close the modal
      getData(); // Refresh the data list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  // Fetch data from the server
  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkOurFeatures"
      );
      setVenueData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getData(); // Fetch data on component load
  }, []);

  // Handle edit functionality
  const handleEdit = (feature) => {
    setEditData(feature);
    setFormData({
      title: feature.title,
      detail: feature.detail,
    });
    setIsEditing(true);
    setShowModal(true); // Open modal for editing
  };

  // Handle delete functionality
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this feature?")) {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkOurFeatures/${id}`
        );
        alert("Feature deleted successfully");
        getData(); // Refresh the data list
      } catch (error) {
        console.log("Failed to delete feature", error);
      }
    }
  };

  // Close modal
  const handleClose = () => {
    setShowModal(false);
    setFormData({
      title: "",
      detail: "",
    });
    setIsEditing(false);
  };

  return (
    <>
      <div className="features-container">
        <h2>Our Features</h2>
        <form onSubmit={handleSubmit} className="features-form">
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="detail" className="form-label">
              Detail
            </label>
            <input
              type="text"
              className="form-control"
              id="detail"
              name="detail"
              value={formData.detail}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn-primary">
            {isEditing ? "Update" : "Save"}
          </button>
        </form>
      </div>

      {/* Preview table with edit and delete options */}
      <div className="container">
        <h3>Preview of Features</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Detail</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {venueData.map((feature, index) => (
              <tr key={feature.id}>
                <td>{index + 1}</td>
                <td>{feature.title}</td>
                <td
                  style={{
                    wordBreak: "break-word",
                    maxWidth: "300px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {feature.detail}
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleEdit(feature)}
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(feature.id)}
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Feature" : "Add Feature"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="detail" className="form-label">
                Detail
              </label>
              <input
                type="text"
                className="form-control"
                id="detail"
                name="detail"
                value={formData.detail}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                height: "50px",
                display: "inline-block",
              }}
            >
              {isEditing ? "Update" : "Save"}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OurFeatures;
