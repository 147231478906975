import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Home/Header";

const PrivacyPolicy = () => {
  const [data, setData] = useState([]);
  const getdata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkhomeExplorePrivacypolicy"
    );
    setData(response.data);
    console.log(response.data, "getdata");
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <Header />
      <div className="terms-page innerpagebg">
        <div
          className="content"
          style={{ marginTop: window.innerWidth <= 786 ? "-40px" : "25px" }}
        >
          <div className="container"></div>
          <div className="breadcrumb breadcrumb-list mb-0">
            <span className="primary-right-round"></span>
            <div className="container">
              <h1 className="text-white">Privacy Policy</h1>
              <ul>
                <li>
                  <a
                    href="/home"
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>

          {data.map((item) => (
            <div className="content">
              <div className="container">
                <h3>{item.title} </h3>
                <div className="condition-details">
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div></div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
