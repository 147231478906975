import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Signup.css";
import emailjs from "emailjs-com";

const Registration = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    isVerify: false,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [success, setsuccess] = useState("");
  const [show, setShow] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "username" && value.length > 12) {
      alert("Username should not exceed 12 letters.");
      return;
    }
    if (name === "mobileNumber") {
      if (/^[0-9]*$/.test(value) && value.length <= 10) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (Object.keys(validationErrors).length > 0) {
      validate();
    }
  };

  function validateEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  const validate = () => {
    let errors = {};

    if (formData.username === "") {
      errors.username = "Username is mandatory";
    } else if (formData.username.length > 50) {
      errors.username = "Username should be within 50 characters";
    }

    if (formData.email === "") {
      errors.email = "Email is mandatory";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Invalid Email";
    }

    if (formData.mobileNumber === "") {
      errors.mobileNumber = "Mobile number is mandatory";
    } else if (formData.mobileNumber.length !== 10) {
      errors.mobileNumber = "Invalid Mobile NUmber";
    }

    if (formData.password === "") {
      errors.password = "Password is mandatory";
    } else if (formData.confirmPassword === "") {
      errors.confirmPassword = "Confirm password is mandatory";
    } else if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = "Confirm password should match with password";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validate()) {
  //     try {
  //       const response = await axios.post(
  //         "https://api.dremerz.net/api/lawnlinksaveuserdata",
  //         formData
  //       );
  //       if (response.data.message === "Success") {
  //         await emailjs
  //           .send("service_1993fip", "template_9vlzohn", formData, {
  //             publicKey: "aJnP5PotDQdflEJWg",
  //           })
  //           .then(
  //             () => {
  //               console.log("SUCCESS!");
  //             },
  //             (error) => {
  //               console.log("FAILED...", error.text);
  //             }
  //           );
  //         alert("Registration success");
  //         window.location.href = "/signin";
  //       }
  //     } catch (error) {
  //       console.error("Registration failed", error);
  //       alert("Registration Failed");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        const getResponse = await axios.get(
          "https://api.dremerz.net/api/lawnlinksaveuserdata"
        );
        const existingUsers = getResponse.data;

        const isDuplicate = existingUsers.some(
          (user) =>
            user.email === formData.email ||
            user.mobilenumber === formData.mobileNumber
        );

        if (isDuplicate) {
          alert(
            "Email or Mobile Number already exists. Please use different details."
          );
          return;
        }

        const postResponse = await axios.post(
          "https://api.dremerz.net/api/lawnlinksaveuserdata",
          formData
        );

        if (postResponse.data.message === "Success") {
          await emailjs
            .send("service_1993fip", "template_9vlzohn", formData, {
              publicKey: "aJnP5PotDQdflEJWg",
            })
            .then(
              () => {
                console.log("SUCCESS!");
              },
              (error) => {
                console.log("FAILED...", error.text);
              }
            );
          alert("Registration success");
          window.location.href = "/signin";
        }
      } catch (error) {
        console.error("Error occurred", error);
        alert("Registration Failed");
      }
    }
  };

  useEffect(() => {
    console.log(validationErrors);
  }, [validationErrors]);

  return (
    <div className="authendication-pages">
      <div className="content">
        <div className="container wrapper no-padding">
          <div className="row no-margin vph-100">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
              <div className="banner-bg register">
                <div className="row no-margin h-100">
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      <div className="text-bg register text-center">
                        <button className="btn btn-limegreen text-capitalize">
                          <i className="fa-solid fa-thumbs-up me-3"></i>Register
                          Now
                        </button>

                        <p>
                          Register now for our innovative sports software
                          solutions, designed to tackle challenges in everyday
                          sports activities and events.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
              <div className="dull-pg">
                <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <header className="text-center">
                      <a href="#">
                        <img
                          src="Listimg/Lawnlogo.jpeg"
                          className="img-fluid lawn-logos"
                          alt="Logo"
                        />
                      </a>
                    </header>
                    <div className="shadow-card text-center ">
                      <h2>Get Started With LawnLink</h2>
                      <p>
                        Ignite your sports journey with LawnLink and get started
                        now.
                      </p>

                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation"></li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="user"
                          role="tabpanel"
                          aria-labelledby="user-tab"
                        >
                          <form action="#" onSubmit={handleSubmit}>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-user"></i>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="username"
                                  placeholder="Username"
                                  value={formData.username}
                                  onChange={handleChange}
                                  required
                                />
                                {validationErrors.username && (
                                  <div className="text-danger">
                                    {validationErrors.username}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-mail"></i>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  placeholder="Email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  required
                                />
                                {validationErrors.email && (
                                  <div className="text-danger">
                                    {validationErrors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-phone"></i>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="mobileNumber"
                                  placeholder="Mobile Number"
                                  value={formData.mobileNumber}
                                  onChange={handleChange}
                                  onInput={(e) => {
                                    if (!/^[0-9]*$/.test(e.target.value)) {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                    }
                                    if (e.target.value.length > 10) {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        10
                                      );
                                    }
                                  }}
                                  required
                                />
                                {validationErrors.mobileNumber && (
                                  <div className="text-danger">
                                    {validationErrors.mobileNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="pass-group group-img">
                                <i className="toggle-password feather-eye-off"></i>
                                <input
                                  type="password"
                                  className="form-control pass-input"
                                  name="password"
                                  placeholder="Password"
                                  value={formData.password}
                                  onChange={handleChange}
                                  required
                                />
                                {validationErrors.password && (
                                  <div className="text-danger">
                                    {validationErrors.password}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="pass-group group-img">
                                <i className="toggle-password-confirm feather-eye-off"></i>
                                <input
                                  type="password"
                                  className="form-control pass-confirm"
                                  name="confirmPassword"
                                  placeholder="Confirm Password"
                                  value={formData.confirmPassword}
                                  onChange={handleChange}
                                  required
                                />
                                {validationErrors.confirmPassword && (
                                  <div className="text-danger">
                                    {validationErrors.confirmPassword}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="form-check d-flex justify-content-start align-items-center policy"
                              style={{
                                height: "50px",
                                display: "inline-block",
                              }}
                            >
                              <div className="d-inline-block">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="policy"
                                  required
                                  style={{ marginTop: "-20px" }}
                                />
                              </div>
                              <label
                                className="form-check-label"
                                htmlFor="policy"
                              >
                                By continuing you indicate that you read and
                                agreed to the{" "}
                                <a
                                  href="#"
                                  style={{
                                    height: "50px",
                                    display: "inline-block",
                                  }}
                                >
                                  Terms of Use
                                </a>
                              </label>
                            </div>
                            <button
                              className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                              type="submit"
                              style={{
                                height: "50px",
                                display: "inline-block",
                              }}
                            >
                              Create Account
                              <i className="feather-arrow-right-circle ms-2"></i>
                            </button>
                            <div className="form-group">
                              <div className="group-img d-flex justify-content-center align-items-center or"></div>
                            </div>

                            <div className="bottom-text text-center">
                              <p>
                                Already have an account?{" "}
                                <a
                                  href="/signin"
                                  className="newsignin-bottomtext"
                                  style={{
                                    height: "50px",
                                    display: "inline-block",
                                  }}
                                >
                                  Sign in!
                                </a>
                              </p>
                              <p className="mb-0">
                                ©2024 LawnLink. All Rights Reserved.
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
