import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "./Booknow.css";
import Dateandtimepicker from "./Dateandtimepicker";
import axios from "axios";
import CryptoJS from "crypto-js";

const BookingPage = (props) => {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [paymentData, setPaymentData] = useState([]);

  const [date, setDate] = useState("");
  const [price, setprice] = useState(0);
  const [data, setData] = useState(props.data);
  const [totalAddedPrice, setTotalAddedPrice] = useState(0);
  const userData = JSON.parse(sessionStorage.getItem("user"));

  const [time, setTime] = useState([
    { selecttime: "12:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "01:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "02:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "03:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "04:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "05:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "06:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "07:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "08:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "09:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "10:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "11:00 am", isbooked: false, disable: false, isOld: false },
    { selecttime: "12:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "01:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "02:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "03:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "04:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "05:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "06:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "07:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "08:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "09:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "10:00 pm", isbooked: false, disable: false, isOld: false },
    { selecttime: "11:00 pm", isbooked: false, disable: false, isOld: false },
  ]);

  const getdata = async () => {
    const response = await axios.get(
      `https://api.dremerz.net/api/lawnlinkPayments?turfName=${props.data?.turfname}&date=${date}&statusCode=PAYMENT_SUCCESS`
    );
    setPaymentData(response.data);

    const currentDate = new Date();
    const selectedDate = new Date(date);

    const isToday = selectedDate.toDateString() === currentDate.toDateString();

    const updatedTime = time.map((slot) => {
      const [hour, period] = slot.selecttime.split(" ");
      let slotTime = parseInt(hour);

      if (period === "pm" && slotTime !== 12) {
        slotTime += 12;
      }
      if (period === "am" && slotTime === 12) {
        slotTime = 0;
      }

      const slotDate = new Date(selectedDate);
      slotDate.setHours(slotTime, 0, 0, 0);

      const isOldTime = isToday && currentDate > slotDate;

      const isBooked =
        props.data?.type !== "gym" &&
        response.data.some((res) => {
          const bookedTimes = res.time.split(",").map((t) => t.trim());
          return bookedTimes.includes(slot.selecttime);
        });

      if (isBooked) {
        return { ...slot, isbooked: true, disable: true, isOld: true };
      }

      if (isOldTime) {
        return { ...slot, isbooked: false, disable: true, isOld: true };
      }

      return { ...slot, isbooked: false, disable: false, isOld: false };
    });

    setTime(updatedTime);

    console.log(response.data, "paymentdata");
  };

  useEffect(() => {
    const fetchData = async () => {
      const bookingDetailsData = {
        TurfName: "Standard Synthetic Court 1",
        date: "27, April 2023",
        startTime: "01:00 PM",
        endTime: "03:00 PM",
        players: 2,
        subtotal: "INR 200",
      };
      setBookingDetails(bookingDetailsData);
    };
    console.log(data);
    fetchData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const finalTime = time.filter((item, index) =>
      item.isbooked && !item.isOld ? item : ""
    );
    if (date != "" && finalTime != "") {
      var da = data;
      da.date = date;
      da.time = finalTime;
      da.price = price;
      da.totalAddedPrice = totalAddedPrice;
      setData(da);
      props.setData(da);
      props.setActiveStep(2);
    } else {
      alert("select date and time slot");
    }
  };

  useEffect(() => {
    setprice(
      data?.priceperhour *
        time.filter((item, index) => (item.isbooked && !item.isOld ? item : ""))
          .length
    );
  }, [time]);
  useEffect(() => {
    const bookedSlots = time.filter(
      (item) => item.isbooked && !item.isOld
    ).length;
    const groupCount = Math.ceil(bookedSlots / 3);
    setTotalAddedPrice(data?.totalprice * groupCount);
  }, [time, data]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    getdata();
  }, [date]);

  return (
    <>
      <div>
        <div className="container">
          <div className="content">
            <div className="content book-cage">
              <div className="container">
                <section className="card mb-40">
                  <div className="text-center mb-40">
                    <h3 className="mb-1">Book Turf</h3>
                    <p className="sub-title mb-0">
                      Hassle-free court bookings and state-of-the-art
                      facilities.
                    </p>
                  </div>
                  <div className="master-academy dull-whitesmoke-bg card">
                    <div className="row d-flex align-items-center justify-content-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="d-sm-flex justify-content-start align-items-center">
                          <a href="#">
                            <img
                              className="corner-radius-10"
                              src={`https://api.dremerz.net/uploads/${data?.image}`}
                              alt="Venue"
                              style={{ height: "300px" }}
                            />
                          </a>
                          <div className="info">
                            <h3 className="mb-2">{data?.turfname}</h3>
                            <p>{data?.address}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <ul className="d-sm-flex align-items-center justify-content-evenly">
                          <li>
                            <h3 className="d-inline-block">
                              Rs:{" "}
                              {data.totalprice
                                ? data.totalprice
                                : data.priceperhour}
                              <br></br>
                              <p>Per Hour</p>
                            </h3>
                          </li>
                          <li>
                            <span>
                              <i className="fas fa-equals"></i>
                            </span>
                          </li>
                          <li>
                            <h3 className="d-inline-block">
                              {data.totalprice
                                ? `Rs ` + totalAddedPrice
                                : `Rs ` + price}
                              <br></br>
                              <p>Subtotal</p>
                            </h3>
                            <p></p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                    <section className="card booking-form">
                      <h3 className="border-bottom">Booking Form</h3>
                      <form onSubmit={handleSubmit}>
                        <Dateandtimepicker
                          date={date}
                          time={time}
                          setDate={setDate}
                          setTime={setTime}
                          data={data}
                        ></Dateandtimepicker>
                      </form>
                    </section>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                    {bookingDetails && (
                      <aside className="card booking-details">
                        <h3 className="border-bottom">Booking Details</h3>
                        <ul>
                          <li>
                            <i className="fa-regular fa-building me-2"></i>
                            {bookingDetails.court}
                            <ul>
                              {time?.map((item) => {
                                return item.isbooked && !item.isOld ? (
                                  <li className="x-circle">
                                    {item.selecttime}
                                  </li>
                                ) : (
                                  ""
                                );
                              })}
                            </ul>
                          </li>
                          <li>
                            <i className="feather-calendar me-2"></i>
                            {date}
                          </li>

                          {/* <li className="d-flex align-items-center">
                            <i className="feather-users me-2"></i>
                            <div className="input-group">
                              <span className="input-group-text">
                                Total Players
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                min="1"
                                value={bookingDetails.players}
                                onChange={(e) =>
                                  setBookingDetails({
                                    ...bookingDetails,
                                    players: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </li> */}
                        </ul>
                        <div className="d-grid btn-block">
                          {/* <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Subtotal: {price}
                          </button> */}
                        </div>
                      </aside>
                    )}
                  </div>
                </div>
                <div className="text-center btn-row">
                  <button
                    className="btn btn-secondary btn-icon"
                    onClick={handleSubmit}
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Next <i className="feather-arrow-right-circle ms-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingPage;
