import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Navbaradmin from "../Navbaradmin/Navbaradmin";
import "./Adminavailableoffers.css";
import React from "react";

const Adminavailableoffers = () => {
  const [formData, setFormData] = useState({
    offerpercent: "",
    location: "",
    turfname: "",
  });
  const [image, setImage] = useState(null);
  const [venueData, setVenueData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleVenueImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });
    if (image) {
      formDataToSend.append("imgSrc", image);
    }

    try {
      if (isEditing) {
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkhomeavailableoffer/${editData.id}`,
          formDataToSend
        );
        alert("Data Updated Successfully");
      } else {
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkhomeavailableoffer",
          formDataToSend
        );
        alert("Data Saved Successfully");
      }

      setFormData({
        offerpercent: "",
        location: "",
        turfname: "",
      });
      setImage(null);
      setShowModal(false);
      getData(); // Refresh the data list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeavailableoffer"
      );
      setVenueData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (offer) => {
    setEditData(offer);
    setFormData({
      offerpercent: offer.offerpercent,
      location: offer.location,
      turfname: offer.turfname,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this offer?")) {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkhomeavailableoffer/${id}`
        );
        alert("Offer deleted successfully");
        getData();
      } catch (error) {
        console.log("Failed to delete offer", error);
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      offerpercent: "",
      location: "",
      turfname: "",
    });
    setIsEditing(false);
  };

  return (
    <>
      <Navbaradmin />
      <div className="offers-posts-container">
        <h2>{isEditing ? "Edit Available Offer" : "Add Available Offer"}</h2>
        <form onSubmit={handleSubmit} className="offers-posts-form">
          <div className="mb-3">
            <label htmlFor="imgSrc" className="form-label">
              Image Source
            </label>
            <input
              type="file"
              className="form-control"
              id="imgSrc"
              name="imgSrc"
              accept="image/*"
              onChange={handleVenueImageChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="offerpercent" className="form-label">
              Offer Percent
            </label>
            <input
              type="text"
              className="form-control"
              id="offerpercent"
              name="offerpercent"
              value={formData.offerpercent}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="location" className="form-label">
              Location
            </label>
            <input
              type="text"
              className="form-control"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="turfname" className="form-label">
              Turf Name
            </label>
            <input
              type="text"
              className="form-control"
              id="turfname"
              name="turfname"
              value={formData.turfname}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn-primary">
            {isEditing ? "Update" : "Save"}
          </button>
        </form>
      </div>

      {/* Preview table with edit and delete options */}
      <div className="container">
        <div className="offers-preview-container">
          <h3>Available Offers</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Offer Percent</th>
                <th>Location</th>
                <th>Turf Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {venueData.map((offer, index) => (
                <tr key={offer.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={`https://api.dremerz.net/uploads/${offer.imgSrc}`}
                      alt={offer.turfname}
                      width="100"
                    />
                  </td>
                  <td>{offer.offerpercent}</td>
                  <td>{offer.location}</td>
                  <td>{offer.turfname}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEdit(offer)}
                      style={{
                        height: "50px",
                        display: "inline-block",
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(offer.id)}
                      style={{
                        height: "50px",
                        display: "inline-block",
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit Offer" : "Add Offer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="imgSrc" className="form-label">
                Image Source
              </label>
              <input
                type="file"
                className="form-control"
                id="imgSrc"
                name="imgSrc"
                accept="image/*"
                onChange={handleVenueImageChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="offerpercent" className="form-label">
                Offer Percent
              </label>
              <input
                type="text"
                className="form-control"
                id="offerpercent"
                name="offerpercent"
                value={formData.offerpercent}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="turfname" className="form-label">
                Turf Name
              </label>
              <input
                type="text"
                className="form-control"
                id="turfname"
                name="turfname"
                value={formData.turfname}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                height: "50px",
                display: "inline-block",
              }}
            >
              {isEditing ? "Update" : "Save"}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Adminavailableoffers;
