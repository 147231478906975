import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Best.css";

const Bestservice = () => {
  const stats = [
    {
      title: "Coaches",
      count: 88,
      description:
        "Highly skilled coaches with extensive expertise in the sport.",
    },
    {
      title: "Courts",
      count: 59,
      description: "Well-maintained courts for optimal badminton experiences.",
    },
  ];

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeFrequentlyAskedQuestions"
      );
      console.log("API Response:", response.data);
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch data");
      console.error(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="section best-services">
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            Extra Benefits, Unmatched <span>Service Excellence</span>
          </h2>
          <p className="sub-title">
            Advance your matches with Lawnlink: Exclusive perks, exceptional
            service.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="best-service-img aos" data-aos="fade-up">
              <img
                src="assets/img/best-service.jpg"
                className="img-fluid"
                alt="Service"
              />
              <div className="service-count-blk">
                {stats.map((stat) => (
                  <div
                    key={stat.title}
                    className={`coach-count ${
                      stat.title === "Courts" ? "court-count" : ""
                    }`}
                  >
                    <h3>{stat.title}</h3>
                    <h2>
                      <span className="counter-up">{stat.count}</span>+
                    </h2>
                    <h4>{stat.description}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="container mt-5">
              <h2>FAQs</h2>
              <div id="faqAccordion" className="accordion">
                {data.map((item, index) => (
                  <div className="card" key={item.id}>
                    <div className="card-header" id={`heading${index}`}>
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link question-text"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}
                          style={{
                            color: "#0A7E52",

                            height: "50px",
                            display: "inline-block",
                          }}
                        >
                          {item.question}
                        </button>
                      </h5>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className="collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="card-body">{item.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bestservice;
