import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

const Changepassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const userdata = JSON.parse(sessionStorage.getItem("user"));

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    try {
      const data = new FormData();
      data.append("password", confirmPassword);

      const response = await axios.put(
        `https://api.dremerz.net/api/lawnlinksaveuserdata/${userdata.id}`,

        data
      );
      if (response.status === 200) {
        alert("Password Changed Successfully");
      } else {
        alert("Password Change Failed...Try After Sometime");
      }
    } catch (err) {
      alert("Failed to change password.");
    }
  };

  const handleReset = () => {
    setNewPassword("");
    setConfirmPassword("");
  };

  const location = useLocation();

  return (
    <>
      <Header />
      <div
        className="content court-bg"
        id="changepassword"
        style={{ marginTop: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="profile-detail-group">
                <div className="section-heading aos" data-aos="fade-up">
                  <h2>
                    <span>Change Password</span>
                  </h2>
                </div>
                <div className="card">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-7 col-md-7">
                        <div className="input-space">
                          <label className="form-label">New Password</label>
                          <i
                            className="toggle-password feather-eye-off"
                            style={{ marginLeft: "10px" }}
                          ></i>

                          <input
                            type="password"
                            className="form-control pass-input"
                            id="new-password"
                            placeholder="Enter New Password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7">
                        <div className="input-space mb-0">
                          <label className="form-label">Confirm Password</label>
                          <i
                            className="toggle-password feather-eye-off"
                            style={{ marginLeft: "10px" }}
                          ></i>

                          <input
                            type="password"
                            className="form-control pass-input"
                            id="confirm-password"
                            placeholder="Enter Confirm Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="save-changes text-end">
                      <button
                        type="button"
                        className="btn btn-primary reset-profile"
                        style={{
                          height: "50px",
                          display: "inline-block",
                        }}
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary save-profile"
                        style={{
                          height: "50px",
                          display: "inline-block",
                        }}
                      >
                        Save Change
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {success && (
          <div
            className="modal fade show"
            tabIndex="-1"
            aria-labelledby="successModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="successModalLabel">
                    Success
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setSuccess(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  Your password has been changed successfully.
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                    data-bs-dismiss="modal"
                    onClick={() => setSuccess(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {error && (
          <div
            className="modal fade show"
            tabIndex="-1"
            aria-labelledby="errorModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="errorModalLabel">
                    Error
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setError(false)}
                  ></button>
                </div>
                <div className="modal-body">{errorMsg}</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                    data-bs-dismiss="modal"
                    onClick={() => setError(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Changepassword;
