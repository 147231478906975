import { useEffect, useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import { Modal, Button } from "react-bootstrap";
import "jspdf-autotable";
import React from "react";

const Bookinglist = () => {
  const [bookingsData, setBookingsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  const userData = JSON.parse(sessionStorage.getItem("user"));

  const getdata = async () => {
    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/lawnlinkPayments?email=${userData.email}`
      );
      setBookingsData(response.data);
    } catch (error) {
      console.log("failed", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleViewDetails = (booking) => {
    setCurrentBooking(booking);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentBooking(null);
  };

  const downloadInvoice = () => {
    console.log(currentBooking);

    if (!currentBooking) {
      console.error("No booking data available");
      return;
    }

    const doc = new jsPDF("portrait", "mm", "a4");

    // Add the "Invoice" title at the top
    doc.setFont("times", "italic");
    doc.setFontSize(40);
    doc.text("Invoice", 105, 30, { align: "center" });
    doc.setTextColor(0, 102, 0);

    // Add the Booking ID under the Invoice heading
    doc.setFont("helvetica", "normal");
    doc.setFontSize(14); // Set appropriate size for booking ID
    const bookingId = currentBooking.merchantUserId || "N/A";
    doc.text(`Booking ID: ${bookingId}`, 105, 40, { align: "center" }); // Centered

    // Set font size and style for headings and details
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0, 102, 0);

    // Invoice Number with different styling
    doc.text(`INVOICE NO.`, 20, 50); // Bold heading
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12); // Smaller for the number
    const invoiceNumber = currentBooking.id || "N/A";
    doc.text(invoiceNumber.toString(), 20, 58);

    // Date and Time (Heading and Text Differentiation)
    const bookingDate = currentBooking.date || "N/A";
    const bookingTime = currentBooking.time || "N/A";

    // Display the booking date and time
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text(`Booking Date:`, 20, 66);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(`${bookingDate}`, 20, 74);

    // Display booking times in 3-per-row format
    const bookingTimes = bookingTime.split(","); // Split times by commas
    let yTimePosition = 82; // Starting Y position for times
    let xTimePosition = 20; // Starting X position

    doc.setFont("helvetica", "bold");
    doc.text("Booking Times:", 20, 82); // Heading for times
    yTimePosition += 8; // Move Y down for the times

    doc.setFont("helvetica", "normal");
    bookingTimes.forEach((time, index) => {
      doc.text(time.trim(), xTimePosition, yTimePosition);
      xTimePosition += 60; // Move X for next time in the row

      if ((index + 1) % 3 === 0) {
        // If 3 times are placed, move to the next line
        yTimePosition += 8;
        xTimePosition = 20; // Reset X position
      }
    });

    // Customer Information (With Differentiated Font Sizes)
    const customerName = currentBooking.name || "N/A";
    const customerEmail = currentBooking.email || "N/A";
    const customerMobile = currentBooking.mobileNumber || "N/A";
    const turfName = currentBooking.turfName || "N/A";

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("TO:", 140, 50); // Heading for customer info

    // Customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(customerName, 140, 58);
    doc.text(`${customerMobile}`, 140, 66);

    // Smaller font size for email
    doc.setFontSize(10);
    doc.text(`${customerEmail}`, 140, 74);

    // Add Booking Details Table with autoTable
    doc.autoTable({
      startY: 100,
      head: [["Field", "Details"]],
      body: [
        ["Customer Name", customerName],
        ["Email", customerEmail],
        ["Mobile", customerMobile],
        ["Turf Name", turfName],
        ["Date of Booking", bookingDate],
        ["Total Price (in Rupees)", `${currentBooking.totalPrice} INR`],
        ["Payment Id", `${currentBooking.transactionId}`],
      ],
      theme: "grid",
      styles: {
        fontSize: 12,
        textColor: 40,
        lineColor: 0,
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [0, 102, 0], // Change to green background
        textColor: [255, 255, 255], // White text color
      },
    });

    // Watermark image logic
    const watermarkImg = new Image();
    watermarkImg.src = "Listimg/Lawnlogo.jpeg";

    watermarkImg.onload = function () {
      doc.setGState(new doc.GState({ opacity: 0.1 }));

      // Add watermark
      doc.addImage(watermarkImg, "PNG", 30, 80, 150, 150);

      doc.setGState(new doc.GState({ opacity: 1 }));

      const endImg = new Image();
      endImg.src = "Listimg/Lawnlogo.jpeg";

      endImg.onload = function () {
        const finalY = doc.lastAutoTable.finalY;
        const pageWidth = doc.internal.pageSize.getWidth();

        // Center the "Thank you" text
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const thankYouText = "Thank you for booking with us!";
        const thankYouX = (pageWidth - doc.getTextWidth(thankYouText)) / 2;
        doc.text(thankYouText, thankYouX, finalY + 20);

        // Center the "LawnLink Booking Service" text
        const serviceText = "LawnLink Booking Service";
        const serviceX = (pageWidth - doc.getTextWidth(serviceText)) / 2;
        doc.setFont("helvetica", "italic");
        doc.text(serviceText, serviceX, finalY + 30);

        // Center the support email
        const supportText = "support@lawnlink.com";
        const supportX = (pageWidth - doc.getTextWidth(supportText)) / 2;
        doc.text(supportText, supportX, finalY + 40);

        // Save the PDF
        doc.save(`invoice_${turfName}.pdf`);
      };
    };
  };

  const handleRefresh = async () => {
    debugger;
    const response = await axios.get(
      `https://api.dremerz.net/api/lawnlinkPayments`
    );
    const filteredResponse = response.data.filter(
      (item) => item.statusCode === "" || item.statusCode === "PAYMENT_PENDING"
    );
    filteredResponse.map(async (item, index) => {
      const responsepost = await axios.post(
        `https://phonepaylawnlink.dremerz.net/paymentuicallback?id=${item.transactionId}`
      );
      if (responsepost.data.data === "updated success") {
        getdata();
      } else {
        alert("something went wrong contact admin");
      }
    });
  };

  console.log(bookingsData?.length, "total bookings");

  return (
    <>
      <div className="content" style={{ marginTop: "40px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="court-tab-content">
                <div className="card card-tableset">
                  <div className="card-body">
                    <div className="coache-head-blk">
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className="court-table-head">
                            <h4>My Bookings</h4>
                            <p>Manage all your Bookings Here</p>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="table-search-top">
                            <div id="tablefilter"></div>
                            <div className="request-coach-list">
                              <div className="card-header-btns">
                                Total Bookings : {bookingsData?.length}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="nav-Recent"
                        role="tabpanel"
                        aria-labelledby="nav-Recent-tab"
                        tabIndex="0"
                      >
                        <div
                          className="table-responsive"
                          style={{ overflowX: "auto" }}
                        >
                          <table className="table table1 table-responsive-sm">
                            <thead className="thead-light">
                              <tr>
                                <th>Turf Name</th>
                                <th style={{ padding: "50px" }}>Date</th>
                                <th style={{ padding: "40px" }}>Time</th>
                                <th>Total Price</th>
                                <th>View Details</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bookingsData?.toReversed().map((item) => (
                                <tr key={item.id}>
                                  <td className="table-date-time">
                                    <h4
                                      style={{
                                        wordWrap: "break-word",
                                        maxWidth: "100px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {item.turfName}
                                    </h4>
                                  </td>
                                  <td>
                                    <span className="pay-dark fs-16">
                                      {item.date}
                                    </span>
                                  </td>
                                  <td>
                                    {/* Break time into three per line */}
                                    <span className="pay-dark fs-16">
                                      {item.time
                                        .split(", ")
                                        .map((time, index) =>
                                          index % 1 === 0 && index !== 0
                                            ? [<br />, time]
                                            : time
                                        )}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="badge bg-success">
                                      <i className="feather-check-square me-1"></i>
                                      {item.totalPrice}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className="badge bg-info"
                                      onClick={() => handleViewDetails(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="feather-eye me-1"></i>
                                      View Details
                                    </span>
                                  </td>

                                  <td>
                                    {item.statusCode != "" &&
                                    (item.statusCode == "PAYMENT_SUCCESS" ||
                                      item.statusCode == "PAYMENT_ERROR") ? (
                                      <span
                                        className={`badge ${
                                          item.statusCode === "PAYMENT_SUCCESS"
                                            ? "bg-success"
                                            : "bg-danger"
                                        }`}
                                      >
                                        {item.statusCode}
                                      </span>
                                    ) : (
                                      <>
                                        <span>
                                          Payment status is{" "}
                                          {item.statusCode != ""
                                            ? item.statusCode
                                            : "not updated"}{" "}
                                          to update click on refresh
                                        </span>
                                        <br></br>
                                        <span
                                          className="badge bg-info"
                                          onClick={() => handleRefresh()}
                                          style={{ cursor: "pointer" }}
                                        >
                                          Refresh
                                        </span>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-footer">
                  <div className="row">
                    <div className="col-md-6">
                      <div id="tablelength"></div>
                    </div>
                    <div className="col-md-6">
                      <div id="tablepage"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for viewing booking details */}
      {currentBooking && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Booking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Turf Name:</strong> {currentBooking.turfName}
            </p>
            <p>
              <strong>Date:</strong> {currentBooking.date}
            </p>
            <p>
              <strong>Time:</strong> {currentBooking.time}
            </p>
            <p>
              <strong>Total Price:</strong> {currentBooking.totalPrice}
            </p>
            {/* Add other booking details as needed */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={downloadInvoice}>
              Download Invoice as PDF
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Bookinglist;
