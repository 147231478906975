import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Profileedit.css";
import { Spinner } from "react-bootstrap";

const UserProfile = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
    image: null,
  });

  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(null);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [userData] = sessionStorage.getItem("user");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const cropImage = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      croppedCanvas.toBlob((blob) => {
        setCroppedImage(blob);
        setFormData({ ...formData, image: blob });
      }, "image/jpeg");
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    setErrors({});

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const checkDataResponse = await axios.get(
        "https://api.dremerz.net/api/lawnlinkuserprofile",
        { params: { email: formData.email } }
      );

      if (checkDataResponse.data && checkDataResponse.data.length > 0) {
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkuserprofile/${checkDataResponse.data[0].id}`,
          formDataToSend
        );
        alert("Profile updated successfully");
      } else {
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkuserprofile",
          formDataToSend
        );
        alert("Profile created successfully");
      }
    } catch (error) {
      alert("An error occurred while saving your profile.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setFormData({
        firstname: data.firstname || "",
        lastname: data.lastname || "",
        email: user.email || "",
        mobile: user.mobile || "",
        address: data.address || "",
        state: data.state || "",
        city: data.city || "",
        zipcode: data.zipcode || "",
        image: null,
      });
    }
  }, [data]);

  return (
    <div className="content">
      <div className="content court-bg new-profile-edit">
        <div className="container">
          <div className="coach-court-list profile-court-list">
            <ul className="nav">
              <li>
                <a className="active" href="/Profileedit">
                  My Profile
                </a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="section-heading aos" data-aos="fade-up">
                <h2>
                  <span>Edit Profile</span>
                </h2>
              </div>

              <div className="card">
                <form>
                  <div className="file-upload-text">
                    <div className="file-upload">
                      <img
                        src="assets/img/icons/img-icon.svg"
                        className="img-fluid"
                        alt="Upload"
                      />
                      <p>Upload Photo</p>
                      <input
                        type="file"
                        id="file-input"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </div>
                    {imageSrc && (
                      <div>
                        <Cropper
                          src={imageSrc}
                          style={{ height: 400, width: "100%" }}
                          // Cropper.js options
                          initialAspectRatio={1}
                          aspectRatio={1}
                          guides={false}
                          ref={cropperRef}
                        />
                        <button
                          type="button"
                          className="btn btn-primary mt-3"
                          style={{
                            height: "50px",
                            display: "inline-block",
                          }}
                          onClick={cropImage}
                        >
                          Crop Image
                        </button>
                      </div>
                    )}
                    {croppedImage && (
                      <img
                        src={URL.createObjectURL(croppedImage)}
                        alt="Cropped Preview"
                        style={{ width: "200px", height: "200px" }}
                      />
                    )}
                    <h5>
                      Upload an image with a minimum size of 150 * 150 pixels
                      (JPG, PNG, SVG).
                    </h5>
                  </div>

                  <div className="card">
                    <div className="row">
                      {/* First Name */}
                      <div className="col-lg-6 col-md-6">
                        <div className="input-space">
                          <label className="form-label">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter First Name"
                            value={formData.firstname}
                            onChange={handleInputChange}
                          />
                          {errors.firstname && (
                            <div className="invalid-feedback">
                              {errors.firstname}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Last Name */}
                      <div className="col-lg-6 col-md-6">
                        <div className="input-space">
                          <label className="form-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            placeholder="Enter Last Name"
                            value={formData.lastname}
                            onChange={handleInputChange}
                          />
                          {errors.lastname && (
                            <div className="invalid-feedback">
                              {errors.lastname}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Address */}
                      <div className="col-lg-12">
                        <div className="input-space">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholder="Enter Address"
                            value={formData.address}
                            onChange={handleInputChange}
                          />
                          {errors.address && (
                            <div className="invalid-feedback">
                              {errors.address}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* State */}
                      <div className="col-lg-12">
                        <div className="input-space">
                          <label className="form-label">State</label>
                          <input
                            type="text"
                            className="form-control"
                            id="state"
                            placeholder="Enter State"
                            value={formData.state}
                            onChange={handleInputChange}
                          />
                          {errors.state && (
                            <div className="invalid-feedback">
                              {errors.state}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* City */}
                      <div className="col-lg-12">
                        <div className="input-space">
                          <label className="form-label">City</label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder="Enter City"
                            value={formData.city}
                            onChange={handleInputChange}
                          />
                          {errors.city && (
                            <div className="invalid-feedback">
                              {errors.city}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Zipcode */}
                      <div className="col-lg-4 col-md-6">
                        <div className="input-space">
                          <label className="form-label">Zipcode</label>
                          <input
                            type="text"
                            className="form-control"
                            id="zipcode"
                            placeholder="Enter Zipcode"
                            value={formData.zipcode}
                            onChange={handleInputChange}
                          />
                          {errors.zipcode && (
                            <div className="invalid-feedback">
                              {errors.zipcode}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="save-changes text-end">
                    <button
                      type="button"
                      className="btn btn-primary save-changes"
                      style={{
                        height: "50px",
                        display: "inline-block",
                      }}
                      onClick={handleSaveChanges}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
