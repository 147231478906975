import { useEffect, useState } from "react";
import axios from "axios";
import "./Premiumoffers.css";
import React from "react";

const Premiumoffers = () => {
  const [formData, setFormData] = useState({
    tag: "",
    price: "",
    offerDetail: "",
    turfname: "",
  });
  const [image, setImage] = useState(null);
  const [venueData, setVenueData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleVenueImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    if (image) {
      formDataToSend.append("imgSrc", image);
    }

    try {
      await axios.post(
        "https://api.dremerz.net/api/lawnlinkhomepremiumoffers",
        formDataToSend
      );

      setFormData({
        tag: "",
        price: "",
        offerDetail: "",
        turfname: "",
      });
      setImage(null);
      alert("Data Saved Successfully");
      getData(); // Refresh the data after adding
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomepremiumoffers"
      );
      setVenueData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  const handleDelete = async (id) => {
    const isConfirm = window.confirm("Are you sure you want to delete?");
    if (!isConfirm) {
      return;
    }

    try {
      await axios.delete(
        `https://api.dremerz.net/api/lawnlinkhomepremiumoffers/${id}`
      );
      alert("Deleted Successfully");
      getData(); // Refresh the data after deleting
    } catch (error) {
      console.log("Failed to delete data", error);
    }
  };

  const handleEdit = (offer) => {
    setFormData({
      tag: offer.tag,
      price: offer.price,
      offerDetail: offer.offerDetail,
      turfname: offer.turfname,
    });
    setEditId(offer.id);
    setShowModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    if (image) {
      formDataToSend.append("imgSrc", image);
    }

    try {
      await axios.put(
        `https://api.dremerz.net/api/lawnlinkhomepremiumoffers/${editId}`,
        formDataToSend
      );

      setFormData({
        tag: "",
        price: "",
        offerDetail: "",
        turfname: "",
      });
      setImage(null);
      setShowModal(false);
      alert("Data Updated Successfully");
      getData(); // Refresh the data after updating
    } catch (error) {
      console.log("Failed to update data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="premium-offer-container">
        <h2>Premium Offers</h2>
        <form onSubmit={handleSubmit} className="premium-offer-form">
          <div className="mb-3">
            <label htmlFor="imgSrc" className="form-label">
              Image Source
            </label>
            <input
              type="file"
              className="form-control"
              id="imgSrc"
              name="imgSrc"
              accept="image/*"
              onChange={handleVenueImageChange}
            />
            {image && (
              <img
                src={URL.createObjectURL(image)}
                alt="Preview"
                className="premium-offer-preview"
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="tag" className="form-label">
              Tag
            </label>
            <input
              type="text"
              className="form-control"
              id="tag"
              name="tag"
              value={formData.tag}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="price" className="form-label">
              Price Per Hour
            </label>
            <input
              type="text"
              className="form-control"
              id="price"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="offerDetail" className="form-label">
              Offer Detail
            </label>
            <input
              type="text"
              className="form-control"
              id="offerDetail"
              name="offerDetail"
              value={formData.offerDetail}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="turfname" className="form-label">
              Turf Name
            </label>
            <input
              type="text"
              className="form-control"
              id="turfname"
              name="turfname"
              value={formData.turfname}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn-primary">
            Save
          </button>
        </form>
      </div>

      {/* Preview with edit and delete functionality */}
      <div className="container">
        <h3>Premium Offers List</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Image</th>
              <th>Tag</th>
              <th>Price</th>
              <th>Offer Detail</th>
              <th>Turf Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {venueData?.map((offer) => (
              <tr key={offer.id}>
                <td>
                  <img
                    src={`https://api.dremerz.net/uploads/${offer.imgSrc}`}
                    alt="image"
                    width="100"
                  />
                </td>
                <td>{offer.tag}</td>
                <td>{offer.price}</td>
                <td>{offer.offerDetail}</td>
                <td>{offer.turfname}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleEdit(offer)}
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(offer.id)}
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for editing */}
      {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Premium Offer</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleUpdate} className="premium-offer-form">
                  <div className="mb-3">
                    <label htmlFor="imgSrc" className="form-label">
                      Image Source
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="imgSrc"
                      name="imgSrc"
                      accept="image/*"
                      onChange={handleVenueImageChange}
                    />
                    {image && (
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                        className="premium-offer-preview"
                      />
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="tag" className="form-label">
                      Tag
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tag"
                      name="tag"
                      value={formData.tag}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="price" className="form-label">
                      Price Per Hour
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="price"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="offerDetail" className="form-label">
                      Offer Detail
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="offerDetail"
                      name="offerDetail"
                      value={formData.offerDetail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="turfname" className="form-label">
                      Turf Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="turfname"
                      name="turfname"
                      value={formData.turfname}
                      onChange={handleChange}
                    />
                  </div>
                  <button type="submit" className="btn-primary">
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Premiumoffers;
