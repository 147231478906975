import React from "react";
import { Heart, MapPin, Calendar, Eye, LogIn } from "react-feather";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import "./Gymlist.css";

const GymGrid = () => {
  const [turfData, setTurfData] = useState([]);

  const [filteredVenues, setFilteredVenues] = useState(turfData);
  const [userData, setUserData] = useState(null);
  const [category, setCategory] = useState("all");
  // const [location, setLocation] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const navvigateTo = (url) => {
    console.log(userData);
    if (userData != null) {
      navigate(url);
    } else {
      navigate("/signin");
    }
  };
  const handlesearch = (event) => {
    event.preventDefault();
    // debugger;
    const category = document.getElementsByName("category")[0].value;
    const location = document.getElementsByName("location")[0].value;
    sessionStorage.setItem("location", location);
    sessionStorage.setItem("category", category);
    if (category === "turfs") {
      // setLocation(location);
    } else {
      navigate("/events");
    }
  };

  const handleclear = (event) => {
    setCategory("all");
    // setLocation("");
    getdata("");
    sessionStorage.removeItem("category");
    sessionStorage.removeItem("location");
    setButtonDisabled(true);
  };

  const handleNavigate = (venue) => {
    const data = venue;
    navigate("/bookingordershome", { state: { data } });
  };

  const handleNavigate1 = (venue) => {
    const data = venue;
    navigate("/turfdetails", { state: { data } });
  };

  const getdata = async (location) => {
    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/lawnlinkregisteredgyms${
          location != "" ? "?location=" + location : ""
        }`
      );
      console.log(response.data);
      setTurfData(response.data);
      setFilteredVenues(response.data);
      console.log(turfData, "turfdata");
    } catch (error) {
      console.error("failed to get data", error);
    }
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
  const filteredUsers = filteredVenues.filter(
    (item) =>
      item.turfname.toLowerCase().includes(searchTerm) ||
      item.location.toLowerCase().includes(searchTerm)
  );
  useEffect(() => {
    var location1 = sessionStorage.getItem("location");

    getdata(location1);
  }, []);

  useEffect(() => {
    var user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user);
  }, []);

  return (
    <>
      <Header />
      <div className="content">
        <br></br>
        <div className="container">
          <div style={{ marginTop: "35px" }}>
            <h2>
              Discover Our <span>Exclusive Gyms</span>
            </h2>
          </div>

          <div className="container new-turflist-container">
            <div className="admin-search-bar">
              <input
                type="text"
                placeholder="Search Here..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="admin-search-input gym-search-input "
                style={{
                  position: window.innerWidth <= 786 ? "relative" : "fixed",
                  marginTop: window.innerWidth <= 786 ? "-166px" : "-108px",
                  marginRight: window.innerWidth <= 786 ? "-23px" : "",
                  zIndex: window.innerWidth <= 786 ? "" : "",
                  height: window.innerWidth <= 786 ? "40px" : "",
                }}
              />
            </div>

            <div className="row">
              <div className="col-lg-12">
                {filteredUsers &&
                  filteredUsers.map((venue, index) => (
                    <div className="featured-venues-item" key={venue.id}>
                      <div className="listing-item listing-item-grid coach-listview">
                        <div className="listing-img">
                          <a>
                            <img
                              src={`https://api.dremerz.net/uploads/${venue.image}`}
                              alt="Venue"
                              style={{ height: "300px" }}
                            />
                          </a>
                          <div className="fav-item-venues">
                            <span className="tag tag-blue">Premium</span>
                            <div className="list-reviews coche-star"></div>
                          </div>
                        </div>
                        <div className="listing-content">
                          <div className="list-reviews near-review near-review-list">
                            <div className="d-flex align-items-center">
                              <span className="rating-bg">
                                {venue.rating}/5
                              </span>
                              <span>{venue.review} Reviews</span>
                            </div>
                            <span className="mile-away">
                              <span>From</span> {venue.totalprice}
                            </span>
                          </div>
                          <h3 className="listing-title">
                            <p href="#">{venue.turfname}</p>
                          </h3>
                          <ul className="mb-2">
                            <li>
                              <span>
                                <MapPin className="me-2" /> {venue.location}
                              </span>
                            </li>
                          </ul>
                          <div className="listing-details-group">
                            <p>{venue.description}</p>
                          </div>
                          <div className="avalbity-review avalbity-review-list">
                            <ul>
                              <li></li>
                            </ul>
                            <ul className="More-coache-list">
                              <li>
                                <a
                                  className="btn btn-primary w-100"
                                  onClick={() => {
                                    handleNavigate1(venue);
                                  }}
                                  style={{
                                    height: "50px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Eye className="me-2" /> View
                                </a>
                              </li>

                              <li>
                                <a
                                  href=""
                                  onClick={() => {
                                    handleNavigate(venue);
                                  }}
                                  className="btn btn-secondary w-100"
                                  style={{
                                    height: "50px",
                                    display: "inline-block",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Calendar className="me-2" /> Book Now
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GymGrid;
