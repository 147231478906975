import React, { useState } from "react";
import axios from "axios";

const Payment = ({ data }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const handlePayment = async () => {
    try {
      const response = await axios.post(
        "https://phonepaylawnlink.dremerz.net/create-order",
        {
          totalPrice: data.subTotal.toString(),
          merchantUserId: `MUID${Date.now()}`,
          mobileNumber: "9876543210",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Full API Response:", response);

      const phonePeResponse = response.data;
      if (phonePeResponse.success) {
        const paymentPageUrl =
          phonePeResponse.data?.instrumentResponse?.redirectInfo?.url;

        if (paymentPageUrl) {
          window.location.href = paymentPageUrl;
        } else {
          throw new Error("Payment page URL not found");
        }
      } else {
        throw new Error(phonePeResponse.message || "Payment initiation failed");
      }
    } catch (error) {
      console.error("Payment Error:", error);
      setPaymentStatus(
        error.message || "Payment initiation failed. Please try again."
      );
    }
  };

  return (
    <div className="container">
      <section>
        <div className="text-center mb-4">
          <h3>Payment</h3>
          <p>
            Secure your booking, complete payment, and enjoy our facilities.
          </p>
        </div>
        <div className="card mb-4">
          <div className="row">
            <div className="col-md-4">
              <img
                className="img-fluid rounded"
                src={`https://api.dremerz.net/uploads/${data.image}`}
                alt="Venue"
              />
            </div>
            <div className="col-md-8">
              <h4>{data.turfname}</h4>
              <p>{data.description}</p>
              <p>{data.address}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="card">
              <h4>Order Summary</h4>
              <ul>
                <li>Turf Name: {data.turfname}</li>
                <li>Date: {data.date}</li>
                <li>
                  Time Slots:{" "}
                  {data.time.map((slot) => slot.selecttime).join(", ")}
                </li>
                <li>Price: ₹{data.price}</li>
                <li>Total: ₹{data.subTotal}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card">
              <h4>Checkout</h4>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="terms"
                  className="form-check-input"
                  checked={termsAccepted}
                  onChange={handleTermsChange}
                />
                <label className="form-check-label" htmlFor="terms">
                  By proceeding, I agree to the{" "}
                  <a href="/privacy-policy">Privacy Policy</a> and{" "}
                  <a href="/terms">Terms of Use</a>.
                </label>
              </div>
              <button
                className="btn btn-primary mt-3"
                onClick={handlePayment}
                disabled={!termsAccepted}
                style={{
                  height: "50px",
                  display: "inline-block",
                }}
              >
                Proceed to Payment
              </button>
              {paymentStatus && (
                <div className="mt-3 alert alert-danger">{paymentStatus}</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Payment;

// import React, { useState } from "react";
// import axios from "axios";
// import emailjs from "emailjs-com";

// const Payment = (props) => {
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [data, setData] = useState(props.data);
//   const [userData, setUserData] = useState(
//     JSON.parse(sessionStorage.getItem("user"))
//   );
//   console.log(userData, "data");

//   const orderSummaryItems = [
//     { icon: "fa-regular fa-building", text: data.turfname },
//     { icon: "feather-calendar", text: data.date },
//     {
//       icon: "feather-clock",
//       text: data.time.map((item) => item.selecttime).join(", "),
//     },
//   ];

//   const handleTermsChange = (e) => {
//     setTermsAccepted(e.target.checked);
//   };

//   const getLastBookingId = async () => {
//     try {
//       const response = await fetch(
//         "https://api.dremerz.net/api/lawnlinkPayments"
//       );
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const data = await response.json();
//       const bookingIds = data.map((item) => item.id);
//       const lastId =
//         bookingIds.length > 0 ? Math.max(...bookingIds.map(Number)) : 0;

//       return lastId;
//     } catch (error) {
//       console.error("Error fetching booking IDs:", error);
//       return 0;
//     }
//   };

//   const sendEmail = async (paymentdata) => {
//     const emailParams = {
//       turfname: paymentdata.get("turfname"),
//       date: paymentdata.get("date"),
//       time: paymentdata.get("time"),
//       price: paymentdata.get("price"),
//       totalprice: paymentdata.get("totalprice"),
//       name: paymentdata.get("name"),
//       email: paymentdata.get("email"),
//       mobile: paymentdata.get("mobile"),
//       bookingid: paymentdata.get("bookingid"),
//       razorPayID: paymentdata.get("razorPayID"),
//     };

//     try {
//       await emailjs.send(
//         "service_7fvxkss",
//         "template_u8lcyg5",
//         emailParams,
//         "raGWxa28UGG-RU5XD"
//       );
//       console.log("Email sent successfully");
//     } catch (error) {
//       console.error("Error sending email:", error);
//     }
//   };

//   const handleSavepayment = async (rid) => {
//     const paymentdata = new FormData();
//     paymentdata.append("turfname", data.turfname);
//     paymentdata.append("date", data.date);
//     paymentdata.append(
//       "time",
//       data.time.map((item) => item.selecttime).join(", ")
//     );
//     paymentdata.append("price", data.price);
//     paymentdata.append("totalprice", data.subTotal);
//     paymentdata.append("name", userData.username);
//     paymentdata.append("email", userData.email);
//     paymentdata.append("mobile", userData.mobileNumber);
//     paymentdata.append("razorPayID", rid);

//     const getCurrentDateTime = () => {
//       const now = new Date();

//       const date = String(now.getDate()).padStart(2, "0");
//       const month = String(now.getMonth() + 1).padStart(2, "0");
//       const year = now.getFullYear();
//       const hours = String(now.getHours()).padStart(2, "0");
//       const minutes = String(now.getMinutes()).padStart(2, "0");
//       const seconds = String(now.getSeconds()).padStart(2, "0");

//       return `${date}+${month}+${year}_${hours}${minutes}${seconds}`;
//     };
//     const secretId = `SecIdLL+${getCurrentDateTime()}+${String(
//       new Date().getSeconds()
//     ).padStart(2, "0")}`;
//     paymentdata.append("secretId", secretId);

//     try {
//       const lastBookingId = await getLastBookingId();
//       const newBookingIdNumber = lastBookingId + 1;
//       const currentDate = new Date();
//       const day = String(currentDate.getDate()).padStart(2, "0");
//       const month = String(currentDate.getMonth() + 1).padStart(2, "0");
//       const year = currentDate.getFullYear();
//       const newBookingId = `LL${day}${month}${year}${String(
//         newBookingIdNumber
//       ).padStart(2, "0")}`;

//       paymentdata.append("bookingid", newBookingId);

//       const response = await axios.post(
//         "https://api.dremerz.net/api/lawnlinkPayments",
//         paymentdata
//       );

//       await sendEmail(paymentdata);

//       alert("Payment successful");
//     } catch (error) {
//       console.log("Payment failed", error);
//       alert("Payment failed. Please try again.");
//     }
//   };

//   // razorpay
//   const handlepayment = () => {
//     if (data.subTotal === "") {
//       alert("Something went wrong... try again later.");
//     } else {
//       const loadScript = (src) => {
//         return new Promise((resolve) => {
//           const script = document.createElement("script");
//           script.src = src;
//           script.onload = () => resolve(true);
//           script.onerror = () => resolve(false);
//           document.body.appendChild(script);
//         });
//       };

//       loadScript("https://checkout.razorpay.com/v1/checkout.js").then(
//         (loaded) => {
//           if (!loaded) {
//             alert("Failed to load Razorpay SDK. Please check your connection.");
//             return;
//           }

//           const options = {
//             // key: "rzp_test_FNu62GtN5qZaVx",
//             // key_secret: "PRIExQVH2t9DAuFTyS4MIwF7",
//             key: "rzp_live_D21nobGzfuqZQV",
//             key_secret: "AL7KBgMIE4Ed3fM0o9QxCqot",
//             amount: data.subTotal * 100,
//             currency: "INR",
//             name: "Lawnlink",
//             description: "Bookings",
//             handler: async function (response) {
//               console.log(response);
//               alert(response.razorpay_payment_id);
//               await handleSavepayment(response.razorpay_payment_id);
//             },
//             prefill: {
//               name: userData.username,
//               email: userData.email,
//               contact: userData.mobileNumber,
//             },
//             notes: {
//               address: "Razorpay Corporate Office",
//             },
//             theme: {
//               color: "#167C82",
//             },
//           };
//           const pay = new window.Razorpay(options);
//           pay.open();
//         }
//       );
//     }
//   };

//   return (
//     <>
//       <div className="container">
//         <div className="content">
//           <section>
//             <div className="text-center mb-40">
//               <h3 className="mb-1">Payment</h3>
//               <p className="sub-title mb-0">
//                 Secure your booking, complete payment, and enjoy our
//                 sophisticated facilities
//               </p>
//             </div>
//             <div className="master-academy dull-whitesmoke-bg card mb-40">
//               <div className="row d-flex align-items-center justify-content-center">
//                 <div className="d-sm-flex justify-content-start align-items-center">
//                   <a href="#">
//                     <img
//                       className="corner-radius-10"
//                       src={`https://api.dremerz.net/uploads/${data.image}`}
//                       alt="Venue"
//                     />
//                   </a>
//                   <div className="info">
//                     <h3 className="mb-2">{data.turfname}</h3>
//                     <p>{data.description}</p>
//                     <p>{data.address}</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="row checkout">
//               <div className="col-lg-7">
//                 <div className="card booking-details">
//                   <h3 className="border-bottom">Order Summary</h3>
//                   <ul className="list-unstyled">
//                     {orderSummaryItems.map((item, index) => (
//                       <li key={index}>
//                         <i className={`${item.icon} me-2`}></i>
//                         {item.text}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-lg-5">
//                 <aside className="card payment-modes">
//                   <h3 className="border-bottom">Checkout</h3>
//                   <div className="form-check d-flex justify-content-start align-items-center policy">
//                     <input
//                       className="form-check-input"
//                       type="checkbox"
//                       id="policy"
//                       checked={termsAccepted}
//                       onChange={handleTermsChange}
//                     />
//                     <label className="form-check-label" htmlFor="policy">
//                       By clicking 'Proceed', I agree to Lawnlink{" "}
//                       <a href="privacy-policy#">Privacy Policy</a> and{" "}
//                       <a href="terms-condition#">Terms of Use</a>
//                     </label>
//                   </div>
//                   <div className="d-grid btn-block">
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       disabled={!termsAccepted}
//                       onClick={handlepayment}
//                     >
//                       Proceed INR {data.subTotal}
//                     </button>
//                     <br></br>
//                   </div>
//                 </aside>
//               </div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Payment;

// // try {
// //   // Step 1: Prepare the payload (request body)
// //   const payload = {
// //     merchantId: "LAWNLONLINE", // Your Merchant ID
// //     orderId: "ord-001", // Unique order ID
// //     amount: 1000, // Amount to be paid
// //     callbackUrl: "https://lawnlink.in/shopping", // Callback URL for payment response
// //     paymentMode: "UPI", // Payment mode (UPI, Card, etc.)
// //     customerDetails: {
// //       name: "ashwin", // Customer's name
// //       email: "dev.ashwinalexander@gmail.com", // Customer's email
// //     },
// //   };

// //   // Step 2: Convert payload to base64
// //   const base64Payload = Buffer.from(JSON.stringify(payload)).toString(
// //     "base64"
// //   );

// //   debugger;
// //   // Step 3: Define salt key and salt index
// //   const saltKey = "7d1b7023-1f66-435e-9308-ada18365ff8a"; // Your Salt Key
// //   const saltIndex = "1"; // Salt index (can vary based on your configuration)

// //   // Step 4: Prepare the data for SHA256 hash
// //   const endpoint = "/pg/v1/pay";
// //   const dataToHash = base64Payload + endpoint + saltKey + saltIndex;

// //   // Step 5: Generate SHA256 hash for the X-VERIFY header
// //   const xVerify = crypto
// //     .createHash("sha256")
// //     .update(dataToHash)
// //     .digest("hex");

// //   // Step 6: Set up the request headers
// //   const headers = {
// //     "Content-Type": "application/json", // Set content type to JSON
// //     Authorization: `Bearer ${saltKey}`, // Use API key in Authorization header
// //     "X-VERIFY": xVerify, // The generated X-VERIFY hash
// //   };

// //   // Step 7: Make the API request to PhonePe
// //   const response = await axios.post(
// //     "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay", // PhonePe production endpoint
// //     payload, // The payload you constructed
// //     { headers }
// //   );

// //   // Step 8: Handle the response
// //   if (response.status === 200) {
// //     console.log("Payment initiated successfully:", response.data);
// //     // Redirect or handle success logic
// //   } else {
// //     console.log("Payment initiation failed:", response.data);
// //     // Handle failure logic
// //   }
// // } catch (error) {
// //   console.error("Error in initiating payment:", error);
// //   // Handle error logic
// // }

// // last payment file
// // last payment file
// // last payment file
// // last payment file
// // last payment file
// // last payment file

// // import React, { useState } from "react";
// // import axios from "axios";
// // import emailjs from "emailjs-com";
// // import CryptoJS from "crypto-js";
// // const Payment = (props) => {
// //   const [termsAccepted, setTermsAccepted] = useState(false);
// //   const [data, setData] = useState(props.data);
// //   const [userData, setUserData] = useState(
// //     JSON.parse(sessionStorage.getItem("user"))
// //   );
// //   console.log(userData, "data");

// //   const orderSummaryItems = [
// //     { icon: "fa-regular fa-building", text: data.turfname },
// //     { icon: "feather-calendar", text: data.date },
// //     {
// //       icon: "feather-clock",
// //       text: data.time.map((item) => item.selecttime).join(", "),
// //     },
// //   ];

// //   const handleTermsChange = (e) => {
// //     setTermsAccepted(e.target.checked);
// //   };

// //   const getLastBookingId = async () => {
// //     try {
// //       const response = await fetch(
// //         "https://api.dremerz.net/api/lawnlinkPayments"
// //       );
// //       if (!response.ok) {
// //         throw new Error("Network response was not ok");
// //       }

// //       const data = await response.json();
// //       const bookingIds = data.map((item) => item.id);
// //       const lastId =
// //         bookingIds.length > 0 ? Math.max(...bookingIds.map(Number)) : 0;

// //       return lastId;
// //     } catch (error) {
// //       console.error("Error fetching booking IDs:", error);
// //       return 0;
// //     }
// //   };

// //   const sendEmail = async (paymentdata) => {
// //     const emailParams = {
// //       turfname: paymentdata.get("turfname"),
// //       date: paymentdata.get("date"),
// //       time: paymentdata.get("time"),
// //       price: paymentdata.get("price"),
// //       totalprice: paymentdata.get("totalprice"),
// //       name: paymentdata.get("name"),
// //       email: paymentdata.get("email"),
// //       mobile: paymentdata.get("mobile"),
// //       bookingid: paymentdata.get("bookingid"),
// //       razorPayID: paymentdata.get("razorPayID"),
// //     };

// //     try {
// //       await emailjs.send(
// //         "service_7fvxkss",
// //         "template_u8lcyg5",
// //         emailParams,
// //         "raGWxa28UGG-RU5XD"
// //       );
// //       console.log("Email sent successfully");
// //     } catch (error) {
// //       console.error("Error sending email:", error);
// //     }
// //   };

// //   const handleSavepayment = async (rid) => {
// //     const paymentdata = new FormData();
// //     paymentdata.append("turfname", data.turfname);
// //     paymentdata.append("date", data.date);
// //     paymentdata.append(
// //       "time",
// //       data.time.map((item) => item.selecttime).join(", ")
// //     );
// //     paymentdata.append("price", data.price);
// //     paymentdata.append("totalprice", data.subTotal);
// //     paymentdata.append("name", userData.username);
// //     paymentdata.append("email", userData.email);
// //     paymentdata.append("mobile", userData.mobileNumber);
// //     paymentdata.append("razorPayID", rid);

// //     const getCurrentDateTime = () => {
// //       const now = new Date();

// //       const date = String(now.getDate()).padStart(2, "0");
// //       const month = String(now.getMonth() + 1).padStart(2, "0");
// //       const year = now.getFullYear();
// //       const hours = String(now.getHours()).padStart(2, "0");
// //       const minutes = String(now.getMinutes()).padStart(2, "0");
// //       const seconds = String(now.getSeconds()).padStart(2, "0");

// //       return `${date}+${month}+${year}_${hours}${minutes}${seconds}`;
// //     };
// //     const secretId = `SecIdLL+${getCurrentDateTime()}+${String(
// //       new Date().getSeconds()
// //     ).padStart(2, "0")}`;
// //     paymentdata.append("secretId", secretId);

// //     try {
// //       const lastBookingId = await getLastBookingId();
// //       const newBookingIdNumber = lastBookingId + 1;
// //       const currentDate = new Date();
// //       const day = String(currentDate.getDate()).padStart(2, "0");
// //       const month = String(currentDate.getMonth() + 1).padStart(2, "0");
// //       const year = currentDate.getFullYear();
// //       const newBookingId = `LL${day}${month}${year}${String(
// //         newBookingIdNumber
// //       ).padStart(2, "0")}`;

// //       paymentdata.append("bookingid", newBookingId);

// //       const response = await axios.post(
// //         "https://api.dremerz.net/api/lawnlinkPayments",
// //         paymentdata
// //       );

// //       await sendEmail(paymentdata);

// //       alert("Payment successful");
// //     } catch (error) {
// //       console.log("Payment failed", error);
// //       alert("Payment failed. Please try again.");
// //     }
// //   };
// //   razorpay
// //   const handlepayment = () => {
// //     if (data.subTotal === "") {
// //       alert("Something went wrong... try again later.");
// //     } else {
// //       const loadScript = (src) => {
// //         return new Promise((resolve) => {
// //           const script = document.createElement("script");
// //           script.src = src;
// //           script.onload = () => resolve(true);
// //           script.onerror = () => resolve(false);
// //           document.body.appendChild(script);
// //         });
// //       };

// //       loadScript("https://checkout.razorpay.com/v1/checkout.js").then(
// //         (loaded) => {
// //           if (!loaded) {
// //             alert("Failed to load Razorpay SDK. Please check your connection.");
// //             return;
// //           }

// //           const options = {
// //             key: "rzp_test_FNu62GtN5qZaVx",
// //             key_secret: "PRIExQVH2t9DAuFTyS4MIwF7",
// //             key: "rzp_live_D21nobGzfuqZQV",
// //             key_secret: "AL7KBgMIE4Ed3fM0o9QxCqot",
// //             amount: data.subTotal * 100,
// //             currency: "INR",
// //             name: "Lawnlink",
// //             description: "Bookings",
// //             handler: async function (response) {
// //               console.log(response);
// //               alert(response.razorpay_payment_id);
// //               await handleSavepayment(response.razorpay_payment_id);
// //             },
// //             prefill: {
// //               name: userData.username,
// //               email: userData.email,
// //               contact: userData.mobileNumber,
// //             },
// //             notes: {
// //               address: "Razorpay Corporate Office",
// //             },
// //             theme: {
// //               color: "#167C82",
// //             },
// //           };
// //           const pay = new window.Razorpay(options);
// //           pay.open();
// //         }
// //       );
// //     }
// //   };

// //   const handlepayment = () => {
// //     const paymentData = {
// //       merchantId: "LAWNLONLINE",
// //       amount: 1000 * 100,
// //       orderId: "order-id-123",
// //       callbackUrl: "https://your-callback-url.com",
// //       customerDetails: {
// //         name: "John Doe",
// //         email: "johndoe@example.com",
// //       },
// //     };

// //     const apiKey = "7d1b7023-1f66-435e-9308-ada18365ff8a";

// //     axios
// //       .post("https://api.phonepe.com/apis/pg/v1/pay", paymentData, {
// //         headers: {
// //           "Content-Type": "application/json",
// //           Authorization: `Bearer ${apiKey}`,
// //         },
// //       })
// //       .then((response) => {
// //         console.log("Payment response:", response.data);
// //       })
// //       .catch((error) => {
// //         console.error(
// //           "Payment error:",
// //           error.response ? error.response.data : error.message
// //         );
// //       });

// //       const MERCHANT_ID = "LAWNLONLINE";
// //       const SALT_KEY_INDEX = "1";
// //       const SALT_KEY = "7d1b7023-1f66-435e-9308-ada18365ff8a";

// //       const paymentRequest = {
// //         merchantId: MERCHANT_ID,
// //         transactionId: `txn_${Date.now()}`, // Unique transaction ID
// //         amount: data.subTotal * 100, // Amount in paisa
// //         merchantUserId: "user_123", // Optional unique user identifier
// //         redirectUrl: "http://localhost:3000/payment-success", // Redirect URL after payment
// //         callbackUrl: "http://localhost:3000/payment-status", // Callback for payment status (needs backend for full implementation)
// //         mobileNumber: userData.mobileNumber || "9999999999", // Optional
// //         paymentInstrument: {
// //           type: "UPI_COLLECT",
// //           upiId: userData.upiId || "test@upi", // Collect UPI ID if applicable
// //         },
// //       };

// //       // Convert payment request to string
// //       const paymentRequestString = JSON.stringify(paymentRequest);

// //       // Generate checksum (hash) using SALT_KEY
// //       const checksum = CryptoJS.HmacSHA256(
// //         paymentRequestString,
// //         SALT_KEY
// //       ).toString(CryptoJS.enc.Hex);

// //       // Create the payment URL
// //       const paymentUrl = "https://api.phonepe.com/apis/pg/v1/pay";

// //       // Redirect the user to PhonePe payment
// //       const form = document.createElement("form");
// //       form.method = "POST";
// //       form.action = paymentUrl;
// //       form.target = "_self";

// //       // Add necessary fields
// //       form.innerHTML = `
// //       <input type="hidden" name="merchantId" value="${MERCHANT_ID}" />
// //       <input type="hidden" name="payload" value='${paymentRequestString}' />
// //       <input type="hidden" name="checksum" value="${checksum}" />
// //     `;

// //       document.body.appendChild(form);
// //       form.submit();
// //   };

// //   return (
// //     <>
// //       <div className="container">
// //         <div className="content">
// //           <section>
// //             <div className="text-center mb-40">
// //               <h3 className="mb-1">Payment</h3>
// //               <p className="sub-title mb-0">
// //                 Secure your booking, complete payment, and enjoy our
// //                 sophisticated facilities
// //               </p>
// //             </div>
// //             <div className="master-academy dull-whitesmoke-bg card mb-40">
// //               <div className="row d-flex align-items-center justify-content-center">
// //                 <div className="d-sm-flex justify-content-start align-items-center">
// //                   <a href="#">
// //                     <img
// //                       className="corner-radius-10"
// //                       src={`https://api.dremerz.net/uploads/${data.image}`}
// //                       alt="Venue"
// //                     />
// //                   </a>
// //                   <div className="info">
// //                     <h3 className="mb-2">{data.turfname}</h3>
// //                     <p>{data.description}</p>
// //                     <p>{data.address}</p>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="row checkout">
// //               <div className="col-lg-7">
// //                 <div className="card booking-details">
// //                   <h3 className="border-bottom">Order Summary</h3>
// //                   <ul className="list-unstyled">
// //                     {orderSummaryItems.map((item, index) => (
// //                       <li key={index}>
// //                         <i className={`${item.icon} me-2`}></i>
// //                         {item.text}
// //                       </li>
// //                     ))}
// //                   </ul>
// //                 </div>
// //               </div>
// //               <div className="col-lg-5">
// //                 <aside className="card payment-modes">
// //                   <h3 className="border-bottom">Checkout</h3>
// //                   <div className="form-check d-flex justify-content-start align-items-center policy">
// //                     <input
// //                       className="form-check-input"
// //                       type="checkbox"
// //                       id="policy"
// //                       checked={termsAccepted}
// //                       onChange={handleTermsChange}
// //                     />
// //                     <label className="form-check-label" htmlFor="policy">
// //                       By clicking 'Proceed', I agree to Lawnlink{" "}
// //                       <a href="privacy-policy#">Privacy Policy</a> and{" "}
// //                       <a href="terms-condition#">Terms of Use</a>
// //                     </label>
// //                   </div>
// //                   <div className="d-grid btn-block">
// //                     <button
// //                       type="button"
// //                       className="btn btn-primary"
// //                       disabled={!termsAccepted}
// //                       onClick={handlepayment}
// //                     >
// //                       Proceed INR {data.subTotal}
// //                     </button>
// //                     <br></br>
// //                   </div>
// //                 </aside>
// //               </div>
// //             </div>
// //           </section>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Payment;
