import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Make sure to install react-bootstrap and bootstrap
import "./Addservice.css";
import React from "react";
const Addservice = () => {
  const [formData, setFormData] = useState({
    title: "",
  });
  const [image, setImage] = useState(null);
  const [venueData, setVenueData] = useState([]); // List of all services
  const [editData, setEditData] = useState(null); // Data for the modal
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [isEditing, setIsEditing] = useState(false); // Toggle between add and edit modes

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle image change
  const handleVenueImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  // Fetch all services
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeservices"
      );
      setVenueData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getdata(); // Fetch data on component load
  }, []);

  // Handle form submission (for both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });
    if (image) {
      formDataToSend.append("imgSrc", image);
    }

    try {
      if (isEditing) {
        // Update existing service
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkhomeservices/${editData.id}`,
          formDataToSend,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        alert("Data Updated Successfully");
      } else {
        // Add new service
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkhomeservices",
          formDataToSend,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        alert("Data Saved Successfully");
      }

      setFormData({ title: "" });
      setImage(null);
      setShowModal(false); // Close modal after submission
      getdata(); // Refresh the list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  // Handle Edit
  const handleEdit = (service) => {
    setEditData(service);
    setFormData({ title: service.title });
    setIsEditing(true);
    setShowModal(true); // Open modal
  };

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkhomeservices/${id}`
        );
        alert("Service deleted successfully");
        getdata(); // Refresh the list
      } catch (error) {
        console.log("Failed to delete service", error);
      }
    }
  };

  // Close Modal
  const handleClose = () => {
    setShowModal(false);
    setFormData({ title: "" });
    setIsEditing(false);
    setImage(null);
  };

  return (
    <>
      <div className="container">
        <h3 style={{ textAlign: "center" }}>Preview of Services</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {venueData.map((service, index) => (
              <tr key={service.id}>
                <td>{index + 1}</td>
                <td>{service.title}</td>
                <td>
                  <img
                    src={`https://api.dremerz.net/uploads/${service.imgSrc}`}
                    alt={service.title}
                    style={{ width: "100px", height: "50px" }}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleEdit(service)}
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(service.id)}
                    style={{
                      height: "50px",
                      display: "inline-block",
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Service" : "Add Service"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="imgSrc" className="form-label">
                Image Source
              </label>
              <input
                type="file"
                className="form-control"
                id="imgSrc"
                name="imgSrc"
                accept="image/*"
                onChange={handleVenueImageChange}
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div> */}
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                height: "50px",
                display: "inline-block",
              }}
            >
              {isEditing ? "Update" : "Save"}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Addservice;
